import axios from 'axios'

import global from '@/common/global'//引用模块进来
import { Message } from 'element-ui';
//1.创建axios实例
const instance = axios.create({
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  responseType: 'json',
  timeout: 0
})
//2.拦截器
instance.interceptors.request.use(config => {
  var token = sessionStorage.getItem('token')
  // var token = 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImRlOGUzNzNlLTA5OTMtNDViYS04Mjc3LWUyOGFmMjgxNWU0OCJ9.hXyiG6-XXFbMC57FWsZIoZNT6cbzPCIiacsjxdTdGIHoJSvgf8ybxefw5LdtjLbqY60d-qaRFVRFZMWlbX55jw'
  if (token) {
    config.headers.Authorization = token
  }
  return config
}, err => {
})
//处理token
function handleToken(code){
  if(global.TokenInvalidFlag  ==0){
    switch (code) {
      case 100: // 缺失令牌
        Message.error("缺失令牌")
        break;
      case 101: // 令牌错误
        Message.error("令牌错误")
        break;
      case 102: // 令牌过期
        Message.error("令牌过期")
        break;
      case 105: // 无效令牌
        Message.error("无效令牌")
        break;
      case 403: //非法用户
        Message.error("非法账户")
        break;
    }
    global.TokenInvalidFlag = 1;
    sessionStorage.clear(); //前台清空session
    setTimeout(function() {
      location.href='/'
    }, 1500)
  }
}

//反应拦截器拦截
instance.interceptors.response.use(response => {
  if (response) {
    switch (response.data.code) {
      case 200: // 成功
        global.TokenInvalidFlag = 0;
        break;
      case 100: // 缺失令牌
        handleToken(100)
        break;
      case 101: // 令牌错误
        handleToken(101)
        break;
      case 102: // 令牌过期
        handleToken(102)
        break;
      case 105: // 无效令牌
        handleToken(105)
        break;
      case 403: //非法用户
        handleToken(403)
        break;
      default:
        break;
    }
  }
  return response.data;
}, err => {
  console.log(err);
})
export const request = instance
