import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false
Vue.config.silent = true // 用于发布版本时屏蔽Vue控制台警告问题
Vue.use(ElementUI)
import moment from "moment";
Vue.prototype.$moment = moment;
Vue.config.silent = true // 用于发布版本时屏蔽Vue控制台警告问题
router.beforeEach((to, from, next) => {
  if (to.path === '/singleSignOn' || to.path === '/errorPage' || to.path === '/details' || to.path === '/noAccess')  {
    next()
    }

  // else if (to.path === '/mainPage' || to.path === '/staffManagement'){
  //   if (sessionStorage.getItem('token') && sessionStorage.getItem('post') === '0') {
  //     next()
  //   } else {
  //     next({path: '/errorPage?message=您的账号非管理员权限，无权使用电脑端管理后台。'})
  //   }
  // }

  else {
    if (sessionStorage.getItem('token')) {
      next()
    } else {
      next({path: '/singleSignOn'})
    }
  }
  // next()
})
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
