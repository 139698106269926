import {request} from "@/service/request"
let url = process.env.VUE_APP_BASE_URL // 接口地址

export const getUserInfo = (params) => {
    return request({
        url: url + '/notice/announcementLoginUser',
        method: 'get',
        params
    })
}
// 获取企微密匙
export const getTokenDemo = (params) => {
    return request({
        url: url + '/notice/getJsapiTicket',
        method: 'get',
        params
    })
}


/* 公告管理*/
// 上传图片
export const postUpload = (params) => {
    return request({
        headers:{"Content-Type":"multipart/form-data"},
        url: url + '/notice/upload',
        method: 'post',
        data: params
    })
}
// 获取发布人下拉
export const getSelectUser = (params) => {
    return request({
        url: url + '/notice/selectUser',
        method: 'get',
        params
    })
}

// 获取公告列表
export const getAnnouncementList = (params) => {
    return request({
        url: url + '/notice/getAnnouncementList',
        method: 'get',
        params
    })
}

// 查看某个公告
export const getNoticeViewAnnouncement = (params) => {
    return request({
        url: url + '/notice/viewAnnouncement',
        method: 'get',
        params
    })
}

// 发布公告
export const postNoticeInsertAnnouncement = (params) => {
    return request({
        url: url + '/notice/insertAnnouncement',
        method: 'post',
        data: params
    })
}

// 修改公告
export const postNoticeUpdateAnnouncement = (params) => {
    return request({
        url: url + '/notice/updateAnnouncement',
        method: 'post',
        data: params
    })
}

// 删除某个公告
export const postNoticeDelAnnouncement = (params) => {
    return request({
        url: url + '/notice/delAnnouncement',
        method: 'post',
        data: params
    })
}

// 上传附件
export const postNoticeUploadAttachment = (params) => {
    return request({
        headers:{"Content-Type":"multipart/form-data"},
        url: url + '/notice/uploadAttachment',
        method: 'post',
        data: params
    })
}

// 企业微信人员架构树状图返回
export const getNoticeGetPermissionSettings = (params) => {
    return request({
        url: url + '/notice/getPermissionSettings',
        method: 'get',
        params
    })
}


/* 公告管理*/
// 查询员工列表
export const getNoticeQueryUserList = (params) => {
    return request({
        url: url + '/notice/queryUserList',
        method: 'get',
        params
    })
}
// 职位名称列表下拉
export const getNoticeSelectSimpleByPosition = (params) => {
    return request({
        url: url + '/notice/selectSimpleByPosition',
        method: 'get',
        params
    })
}
// 部门名称列表下拉
export const getNoticeSelectSimpleByDepartment = (params) => {
    return request({
        url: url + '/notice/selectSimpleByDepartment',
        method: 'get',
        params
    })
}

// 修改员工信息
export const postNoticeUpdatetUser = (params) => {
    return request({
        url: url + '/notice/updatetUser',
        method: 'post',
        data: params
    })
}
// 部门下拉
export const getNoticeDropdownDepartment = (params) => {
    return request({
        url: url + '/notice/dropdownDepartment',
        method: 'get',
        params
    })
}

// 可选人员下拉
export const getNoticeCheckPersonnel = (params) => {
    return request({
        url: url + '/notice/checkPersonnel',
        method: 'get',
        params
    })
}


/* 历史记录*/
// 获取历史记录
export const getNoticeQueryHistoryList = (params) => {
    return request({
        url: url + '/notice/queryHistoryList',
        method: 'get',
        params
    })
}

/* 公告详情*/
// 阅读公告
export const postNoticeSubscription = (params) => {
    return request({
        url: url + '/notice/subscription',
        method: 'post',
        data: params
    })
}
// 附件下载
export const getNoticeOssDownload = (params) => {
    return request({
        url: url + '/notice/ossDownload',
        method: 'get',
        responseType: 'blob',
        params
    })
}

/* 主体管理*/
// 主体管理列表
export const getSubject = (params) => {
    return request({
        url: url + '/subject/getSubject',
        method: 'get',
        params
    })
}
// 主体新增
export const postAddSubject = (params) => {
    return request({
        url: url + '/subject/addSubject',
        method: 'post',
        data: params
    })
}
// 主体修改
export const postUpdateSubject = (params) => {
    return request({
        url: url + '/subject/updateSubject',
        method: 'post',
        data: params
    })
}
// 主体删除
export const postDeleteSubject = (params) => {
    return request({
        url: url + '/subject/deleteSubject',
        method: 'post',
        data: params
    })
}
// 主体下拉列表
export const getSubjectDown = (params) => {
    return request({
        url: url + '/subject/getSubjectDown',
        method: 'get',
        params
    })
}
