export default [
    // 登录页面
    {
        path: '/singleSignOn',
        name: 'singleSignOn',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/singleSignOn")
    },
    // 错误页面
    {
        path: '/errorPage',
        name: 'errorPage',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/errorPage")
    },
    // 无权限页面
    {
        path: '/noAccess',
        name: 'noAccess',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/noAccess")
    },
    {
        path: '/',
        name: 'home',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/home"),
        children:[
            {
                path: '/mainPage',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/index"),
                children: [
                    // 公告管理 mainPage
                    {
                        path: '',
                        name: 'mainPage',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/mainPage")
                    },
                    // 公告管理 mainPageNew
                    {
                        path: '/mainPageNew',
                        name: 'mainPageNew',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/mainPageNew")
                    },
                    // 员工管理 staffManagement
                    {
                        path: '/staffManagement',
                        name: 'staffManagement',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/staffManagement")
                    },
                    // 员工管理 subjectManagement
                    {
                        path: '/subjectManagement',
                        name: 'subjectManagement',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/subjectManagement")
                    },
                ]
            },
            // 历史记录 history
            {
                path: '/history',
                name: 'history',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/history")
            },
            // 公告消息 details
            {
                path: '/details',
                name: 'details',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/details")
            },
            // 公告撤回
            {
                path: '/error',
                name: 'error',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/error")
            },
        ],
    },
]
